import { 
  NavigationDots, 
  SocialMedia 
} from '../components';
import { ComponentType } from 'react';

const AppWrap = (Component: ComponentType, idName: string, classNames: string = '') => () => {
  return (
    <div id={idName} className={`app__container ${classNames}`}>
      <SocialMedia />
      <div className="app__wrapper app__flex">
        <Component />
      </div>
      <NavigationDots active={idName} />
    </div>
  );
};

export default AppWrap;
