import { 
  AppWrap, 
  MotionWrap 
} from '../../wrapper';
import { motion } from 'framer-motion';
import { 
  useState, 
  useEffect 
} from 'react';
import { AboutInterface } from '../../interfaces';
import { dataJson } from '../../constants/index';
import './About.scss';

const About = () => {

  return (
    <>
      <h2 className="head-text">I Know that <span>Great apps</span> <br />means  <span>Great Business</span></h2>

      <div className="app__profiles">
        {dataJson.abouts.map((about: AboutInterface, index: number) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={about.imgUrl} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);
